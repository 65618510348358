var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col h-full" },
    [
      _c(
        "nav",
        {
          staticClass:
            "flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end"
        },
        [
          _c("v-back", {
            attrs: { fallback: _vm.$router.resolve({ name: "teams-overview" }) }
          }),
          _vm.user && Object.values(_vm.UserRoles).indexOf(_vm.user.role) >= 0
            ? _c("p", {
                staticClass:
                  "font-semibold w-full text-center -ml-7 pointer-events-none",
                domProps: {
                  textContent: _vm._s(_vm.$t("user_roles." + _vm.user.role))
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("ul", { staticClass: "list-disc list-inside" }, [
        _vm.removingError
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.removingError) }
            })
          : _vm._e(),
        _vm.updatingDutyError
          ? _c("li", {
              staticClass: "form-error",
              domProps: { textContent: _vm._s(_vm.updatingDutyError) }
            })
          : _vm._e()
      ]),
      !_vm.loading && !_vm.error && _vm.user
        ? _c("v-scrollable", [
            _c("div", { staticClass: "flex-grow flex flex-col" }, [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center ml-4 py-6 border-b border-blue-500"
                  },
                  [
                    _vm.user.avatar !== null
                      ? _c("img", {
                          staticClass: "h-24 rounded-full",
                          attrs: {
                            src: _vm.user.avatar.medium,
                            alt: _vm.$t("general.label_profile_picture")
                          }
                        })
                      : _c("img", {
                          staticClass: "h-24 rounded-full w-full",
                          attrs: {
                            src: require("@/assets/images/avatar_placeholder.svg"),
                            alt: _vm.$t("general.label_profile_picture")
                          }
                        })
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "detail-field" },
                  [
                    _c("p", {
                      staticClass: "detail-field-label",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("the_user_detail.label_on_duty")
                        )
                      }
                    }),
                    _c("v-switch", {
                      attrs: { disabled: !_vm.canUpdateDuty },
                      on: { input: _vm.openDutyUpdateConfirmation },
                      model: {
                        value: _vm.updatedOnDuty,
                        callback: function($$v) {
                          _vm.updatedOnDuty = $$v
                        },
                        expression: "updatedOnDuty"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "detail-field" }, [
                  _c("p", {
                    staticClass: "detail-field-label",
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_user_detail.label_name"))
                    }
                  }),
                  _c("p", {
                    staticClass: "truncate",
                    domProps: {
                      textContent: _vm._s(
                        (_vm.user.firstname || "") +
                          " " +
                          (_vm.user.lastname || "")
                      )
                    }
                  })
                ]),
                _vm.user.phone
                  ? _c("div", { staticClass: "detail-field" }, [
                      _c("p", {
                        staticClass: "detail-field-label",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("the_user_detail.label_phone")
                          )
                        }
                      }),
                      _c("p", {
                        staticClass: "truncate",
                        domProps: { textContent: _vm._s(_vm.user.phone) }
                      })
                    ])
                  : _vm._e(),
                _vm.user.email
                  ? _c("div", { staticClass: "detail-field" }, [
                      _c("p", {
                        staticClass: "detail-field-label",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("the_user_detail.label_email")
                          )
                        }
                      }),
                      _c("p", {
                        staticClass: "truncate",
                        domProps: { textContent: _vm._s(_vm.user.email) }
                      })
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "ml-4 mt-8 flex flex-col" }, [
                  _c("p", {
                    staticClass:
                      "text-sm uppercase text-gray-500 border-b border-blue-500 pb-2",
                    domProps: {
                      textContent: _vm._s(_vm.$t("the_user_detail.label_teams"))
                    }
                  }),
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.user.teams, function(team) {
                        return _c(
                          "li",
                          {
                            key: team.id,
                            staticClass:
                              "flex items-center justify-between border-b border-blue-500 py-2"
                          },
                          [
                            _c("div", { staticClass: "leading-tight" }, [
                              _c("p", {
                                staticClass: "font-semibold",
                                domProps: { textContent: _vm._s(team.name) }
                              }),
                              _c("p", {
                                staticClass: "text-xs uppercase text-gray-500",
                                domProps: { textContent: _vm._s(team.role) }
                              })
                            ]),
                            team.canRemoveFromUser
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "h-4 w-4 btn-round mr-4",
                                    class: {
                                      "btn-loading":
                                        _vm.removedTeam &&
                                        _vm.removedTeam.id === team.id
                                    },
                                    attrs: { disabled: _vm.removedTeam },
                                    on: {
                                      click: function($event) {
                                        return _vm.showRemovalConfirmation(team)
                                      }
                                    }
                                  },
                                  [
                                    !_vm.removedTeam ||
                                    _vm.removedTeam.id !== team.id
                                      ? _c("img", {
                                          staticClass: "h-4 w-4",
                                          attrs: {
                                            src: require("@/assets/images/close.svg"),
                                            alt: _vm.$t(
                                              "the_user_detail.button_remove_from_team"
                                            ),
                                            title: _vm.$t(
                                              "the_user_detail.button_remove_from_team"
                                            )
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _vm.user.teams.length <= 0
                        ? _c("li", {
                            staticClass: "pr-4 py-3 text-md text-gray-900",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("the_user_detail.label_empty_team_list")
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "mt-8 flex-grow flex flex-col" },
                [
                  _c("p", {
                    staticClass:
                      "text-sm uppercase text-gray-500 border-b border-blue-500 ml-4 pb-2",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("the_user_detail.label_assigned_reports")
                      )
                    }
                  }),
                  _c("incidents-list", {
                    attrs: { incidents: _vm.user.assignedIncidents }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm.loading ? _c("v-loading") : _vm._e(),
      _vm.error
        ? _c("v-error", {
            model: {
              value: _vm.error,
              callback: function($$v) {
                _vm.error = $$v
              },
              expression: "error"
            }
          })
        : _vm._e(),
      _vm.removedTeam
        ? _c("v-confirm", {
            attrs: {
              header: _vm.$t(
                "the_user_detail.label_header_confirm_removal_from_team",
                {
                  team: _vm.removedTeam.name
                }
              ),
              body: _vm.$t(
                "the_user_detail.label_body_confirm_removal_from_team",
                { team: _vm.removedTeam.name }
              )
            },
            on: { confirm: _vm.removeFromTeam, cancel: _vm.cancelRemoval }
          })
        : _vm._e(),
      _vm.showDutyUpdateConfirmation
        ? _c("v-confirm", {
            attrs: {
              header: _vm.user.onDuty
                ? _vm.$t("the_user_detail.label_header_confirm_end_duty")
                : _vm.$t("the_user_detail.label_header_confirm_start_duty"),
              body: _vm.user.onDuty
                ? _vm.$t("the_user_detail.label_body_confirm_end_duty")
                : _vm.$t("the_user_detail.label_body_confirm_start_duty")
            },
            on: { confirm: _vm.updateDuty, cancel: _vm.cancelDutyUpdate }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }