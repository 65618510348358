<template>
  <div class="flex flex-col h-full">
    <nav class="flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <v-back :fallback="$router.resolve({ name: 'teams-overview'})"/>
      <p
        v-if="user && Object.values(UserRoles).indexOf(user.role) >= 0"
        v-text="$t(`user_roles.${user.role}`)"
        class="font-semibold w-full text-center -ml-7 pointer-events-none"
      ></p>
    </nav>
    <ul class="list-disc list-inside">
      <li
        v-if="removingError"
        v-text="removingError"
        class="form-error"></li>
      <li
        v-if="updatingDutyError"
        v-text="updatingDutyError"
        class="form-error"></li>
    </ul>
    <v-scrollable v-if="!loading && !error && user">
      <div class="flex-grow flex flex-col">
        <div>
          <div class="flex justify-center ml-4 py-6 border-b border-blue-500">
            <img
              v-if="user.avatar !== null"
              :src="user.avatar.medium"
              :alt="$t('general.label_profile_picture')"
              class="h-24 rounded-full"
            >
            <img
              v-else
              src="@/assets/images/avatar_placeholder.svg"
              :alt="$t('general.label_profile_picture')"
              class="h-24 rounded-full w-full"
            >
          </div>
          <div class="detail-field">
            <p
              v-text="$t('the_user_detail.label_on_duty')"
              class="detail-field-label"></p>
            <v-switch
              :disabled="!canUpdateDuty"
              v-model="updatedOnDuty"
              @input="openDutyUpdateConfirmation"/>
          </div>
          <div class="detail-field">
            <p
              v-text="$t('the_user_detail.label_name')"
              class="detail-field-label"></p>
            <p
              v-text="`${user.firstname || ''} ${user.lastname || ''}`"
              class="truncate"></p>
          </div>
          <div
            v-if="user.phone"
            class="detail-field">
            <p
              v-text="$t('the_user_detail.label_phone')"
              class="detail-field-label"></p>
            <p
              v-text="user.phone"
              class="truncate"></p>
          </div>
          <div
            v-if="user.email"
            class="detail-field">
            <p
              v-text="$t('the_user_detail.label_email')"
              class="detail-field-label"></p>
            <p
              v-text="user.email"
              class="truncate"></p>
          </div>
          <div class="ml-4 mt-8 flex flex-col">
            <p
              v-text="$t('the_user_detail.label_teams')"
              class="text-sm uppercase text-gray-500 border-b border-blue-500 pb-2"
            ></p>
            <ul>
              <li
                v-for="team in user.teams"
                :key="team.id"
                class="flex items-center justify-between border-b border-blue-500 py-2"
              >
                <div class="leading-tight">
                  <p
                    class="font-semibold"
                    v-text="team.name">
                  </p>
                  <p
                    class="text-xs uppercase text-gray-500"
                    v-text="team.role">
                  </p>
                </div>
                <button
                  v-if="team.canRemoveFromUser"
                  @click="showRemovalConfirmation(team)"
                  class="h-4 w-4 btn-round mr-4"
                  :class="{'btn-loading': removedTeam && removedTeam.id === team.id}"
                  :disabled="removedTeam"
                >
                  <img
                    v-if="!removedTeam || removedTeam.id !== team.id"
                    src="@/assets/images/close.svg"
                    :alt="$t('the_user_detail.button_remove_from_team')"
                    :title="$t('the_user_detail.button_remove_from_team')"
                    class="h-4 w-4"
                  >
                </button>
              </li>
              <li
                v-if="user.teams.length <= 0"
                v-text="$t('the_user_detail.label_empty_team_list')"
                class="pr-4 py-3 text-md text-gray-900">
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-8 flex-grow flex flex-col">
          <p
            v-text="$t('the_user_detail.label_assigned_reports')"
            class="text-sm uppercase text-gray-500 border-b border-blue-500 ml-4 pb-2"
          ></p>
          <incidents-list :incidents="user.assignedIncidents"/>
        </div>
      </div>
    </v-scrollable>
    <v-loading v-if="loading"/>
    <v-error
      v-if="error"
      v-model="error"/>
    <v-confirm
      v-if="removedTeam"
      @confirm="removeFromTeam"
      @cancel="cancelRemoval"
      :header="$t('the_user_detail.label_header_confirm_removal_from_team', {
        team: removedTeam.name
      })"
      :body="$t('the_user_detail.label_body_confirm_removal_from_team', { team: removedTeam.name })"
    />
    <v-confirm
      v-if="showDutyUpdateConfirmation"
      @confirm="updateDuty"
      @cancel="cancelDutyUpdate"
      :header="user.onDuty
        ? $t('the_user_detail.label_header_confirm_end_duty')
        : $t('the_user_detail.label_header_confirm_start_duty')"
      :body="user.onDuty
        ? $t('the_user_detail.label_body_confirm_end_duty')
        : $t('the_user_detail.label_body_confirm_start_duty')"
    />
  </div>
</template>

<script>
  import VBack from '@/components/common/VBack.vue';
  import VError from '@/components/common/VError.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import VConfirm from '@/components/common/VConfirm.vue';
  import VSwitch from '@/components/common/VSwitch.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import IncidentsList from '@/components/lists/IncidentsList.vue';
  import EventBus from '@/eventbus';
  import UserRoles from '@/constants/UserRoles';

  export default {
    name: 'TheUserDetail',
    components: {
      VBack,
      VError,
      VLoading,
      VConfirm,
      VSwitch,
      VScrollable,
      IncidentsList,
    },
    mixins: [EventBus],
    props: {
      id: { type: String, default: '' },
    },
    data() {
      return {
        UserRoles,
        loading: true,
        error: null,
        removedTeam: null,
        removingError: null,
        updatedOnDuty: false,
        showDutyUpdateConfirmation: false,
        updatingDutyError: null,
      };
    },
    computed: {
      user() {
        return this.$store.getters['user/item'];
      },
      event() {
        return this.$store.getters['auth/event'];
      },
      canUpdateDuty() {
        if (this.updatedOnDuty !== this.user.onDuty) {
          return false;
        }

        return (!this.user.onDuty && this.user.canStartDuty)
          || (this.user.onDuty && this.user.canEndDuty);
      },
    },
    watch: {
      id() {
        this.loading = true;

        this.loadUser();
      },
      user(newUser, oldUser) {
        if (this.user === null || (oldUser && oldUser.id === this.user.id)) {
          return;
        }

        if (this.user.lat === null || this.user.long === null) {
          return;
        }

        EventBus.$emit('center_changed', {
          lat: this.user.lat,
          lng: this.user.long,
        });

        EventBus.$emit('zoom_changed', 20);
      },
    },
    created() {
      this.loading = true;

      this.loadUser();

      this.$watch('user.onDuty', () => {
        if (!this.user) {
          return;
        }

        this.updatedOnDuty = this.user.onDuty;
        this.showDutyUpdateConfirmation = false;
      });
    },
    beforeDestroy() {
      this.$store.commit('user/clearItem');
    },
    methods: {
      loadUser() {
        this.$store
          .dispatch('user/loadById', { id: this.id, eventId: this.event.id })
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      showRemovalConfirmation(team) {
        this.removedTeam = team;
      },
      removeFromTeam() {
        this.removingError = null;

        if (!this.removedTeam) {
          return;
        }

        this.$store
          .dispatch('user/removeFromTeam', {
            user: this.user,
            team: this.removedTeam,
          })
          .then(() => this.$store.dispatch('user/loadById', {
            id: this.id,
            eventId: this.event.id,
          }))
          .then(() => {
            this.removedTeam = null;
          }).catch((error) => {
            this.removedTeam = null;
            this.removingError = error;
          });
      },
      cancelRemoval() {
        this.removedTeam = null;
        this.removingError = null;
      },
      openDutyUpdateConfirmation() {
        if (this.updatedOnDuty === this.user.onDuty) {
          return;
        }

        this.showDutyUpdateConfirmation = true;
      },
      updateDuty() {
        this.updatingDutyError = null;

        this.$store
          .dispatch(
            this.updatedOnDuty ? 'user/startDuty' : 'user/endDuty',
            { user: this.user, eventId: this.event.id },
          )
          .then(() => {
            this.updatedOnDuty = this.user.onDuty;
            this.showDutyUpdateConfirmation = false;
          })
          .catch((error) => {
            this.updatedOnDuty = this.user.onDuty;
            this.showDutyUpdateConfirmation = false;
            this.updatingDutyError = error;
          });
      },
      cancelDutyUpdate() {
        this.updatedOnDuty = this.user.onDuty;
        this.showDutyUpdateConfirmation = false;
        this.updatingDutyError = null;
      },
    },
  };
</script>
